import { dev } from '$app/environment'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
	dsn: 'https://077694f2fa9b4e139f7b1906524e7873@o481936.ingest.sentry.io/4505329478139904',

	enabled: !dev,

	tracesSampleRate: 0.1,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry()
