import * as client_hooks from '../../../src/hooks.client/index.ts';
import * as universal_hooks from '../../../src/hooks.client';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,6,5,7,8];

export const dictionary = {
		"/(content)": [~12,[4]],
		"/(content)/(marketing)/about": [17,[4,6]],
		"/(content)/(account)/account": [~13,[4]],
		"/(content)/admin/impersonate": [43,[4]],
		"/(content)/(marketing)/aup": [18,[4,6]],
		"/(content)/(blog)/blog/[taxonomy=type]/[name]/[[page=int]]": [~16,[4,5]],
		"/(content)/(blog)/blog/[[page=int]]": [~15,[4,5]],
		"/(content)/(blog)/blog/[...slug]": [~14,[4,5]],
		"/(content)/(marketing)/contact": [19,[4,6]],
		"/(app)/(convert)/convert": [10,[2]],
		"/(content)/(marketing)/dmca": [20,[4,6]],
		"/(content)/(marketing)/faq": [21,[4,6]],
		"/(content)/(marketing)/jobs": [22,[4,6]],
		"/(content)/(marketing)/partners": [23,[4,6]],
		"/(content)/(marketing)/press": [24,[4,6]],
		"/(content)/(marketing)/privacy": [25,[4,6]],
		"/(content)/(marketing)/returns": [26,[4,6]],
		"/(content)/(marketing)/security": [27,[4,6]],
		"/(content)/(store)/store/cart": [30,[4,7]],
		"/(content)/(store)/store/image/[id=image]/[slug]": [~31,[4,7,8]],
		"/(content)/(store)/store/success": [~32,[4,7]],
		"/(content)/(store)/store/[...category]": [~29,[4,7]],
		"/(content)/(marketing)/terms": [28,[4,6]],
		"/(content)/(tools)/tools": [33,[4,9]],
		"/(content)/(tools)/tools/check-your-chart": [34,[4,9]],
		"/(content)/(tools)/tools/closest-dmc-thread-color-lookup": [35,[4,9]],
		"/(content)/(tools)/tools/compare-differences-between-two-images": [36,[4,9]],
		"/(content)/(tools)/tools/cross-stitch-completion-time-estimator": [37,[4,9]],
		"/(content)/(tools)/tools/cross-stitch-fabric-size-calculator": [38,[4,9]],
		"/(content)/(tools)/tools/cross-stitch-skein-estimator": [39,[4,9]],
		"/(content)/(tools)/tools/dmc-color-scheme-generator": [40,[4,9]],
		"/(content)/(tools)/tools/match-closest-dmc-thread-to-color-tool": [41,[4,9]],
		"/(content)/(tools)/tools/wifi-sharing-qrcode-pattern-generator": [42,[4,9]],
		"/(app)/(viewer)/viewer": [11,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';